import React from 'react'

import { Link } from 'react-router-dom'

import Mask_Group_1_d_pattern from '../styles/assets/Mask_Group_1_d_pattern.png'
import heroImage2x from '../styles/assets/heroImage2x.png'
import GA_icon_5_way_2x_b from '../styles/assets/GA_icon_5_way_2x_b.png'

const Experience = () => {
  return (
    <>


      <div className='experience'>

        <div id="GA___Experince" className="GA___Experince_Class">
          <div className="About_Content_Class">
            <div className="About_Title_Class">
              <div className="Professional_Experience_Class">
                <span>Professional Experience</span>
              </div>
              <div className="Hint_logo_Class">
                <svg className="Line_5" viewBox="0 0 22 9">
                  <path fill="transparent" stroke="rgba(236,54,141,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_5_Class" d="M 22 0 L 0 9">
                  </path>
                </svg>
                <svg className="Line_2" viewBox="0 0 24 2">
                  <path fill="transparent" stroke="rgba(39,149,178,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_2_Class" d="M 24 0 L 0 0">
                  </path>
                </svg>
                <svg className="Line_4" viewBox="0 0 22 9">
                  <path fill="transparent" stroke="rgba(83,216,251,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_4_Class" d="M 0 0 L 22 9">
                  </path>
                </svg>
              </div>
            </div>
          </div>
          <div className="Header_b_Class">
            <div className="Mask_Group_1_b_Class">
              <img src={Mask_Group_1_d_pattern} alt="navbar" className="Mask_Group_1_b_Class" />
            </div>
            <Link to="/">
              <img className="GA_icon_5_way_2x_b_Class" src={GA_icon_5_way_2x_b} />
            </Link>
            <div className="Nav_bar_b_Class">
              <svg className="Rectangle_3_b">
                <rect fill="rgba(39,149,178,1)" className="Rectangle_3_b_Class" rx={1} ry={1} x={0} y={0} width={30} height={2}>
                </rect>
              </svg>
              <Link to='/about'>
                <div className="ABOUT_ca_Class">
                  <span>ABOUT</span>
                </div>
              </Link>
              <Link to='/projects'>
                <div className="PROJECTS_cb_Class">
                  <span>PROJECTS</span>
                </div>
              </Link>
              <Link to='/experience'>
                <div className="PROFFESIONAL_EXPERIENCE_cc_Class">
                  <span>PROFFESIONAL EXPERIENCE</span>
                </div>
              </Link>
            </div>
          </div>
          <div className="Component_16___1 Component_16___1_Class">
            <svg className="Rectangle_6">
              <rect fill="rgba(255,255,255,1)" stroke="rgba(112,112,112,1)" strokeWidth="1px" strokeLinejoin="miter" strokeLinecap="butt" strokeMiterlimit={4} shapeRendering="auto" className="Rectangle_6_Class" rx={0} ry={0} x={0} y={0} width={1063} height={976}>
              </rect>
            </svg>
            <div className="Skills_Title_Class">
              <a target="_blank" rel="noopener noreferrer" href="http://altaire.com/">
                <div className="Altaire_Limited_Class">
                  <span>Altaire Limited</span>
                </div>
              </a>
              <div className="Hint_logo__Class">
                <svg className="Line_5_" viewBox="0 0 23 10">
                  <path fill="transparent" stroke="rgba(236,54,141,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_5__Class" d="M 23 0 L 0 10">
                  </path>
                </svg>
                <svg className="Line_2_" viewBox="0 0 24 2">
                  <path fill="transparent" stroke="rgba(39,149,178,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_2__Class" d="M 24 0 L 0 0">
                  </path>
                </svg>
                <svg className="Line_4_ba" viewBox="0 0 22 10">
                  <path fill="transparent" stroke="rgba(83,216,251,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_4_ba_Class" d="M 0 0 L 22 10">
                  </path>
                </svg>
              </div>
            </div>
            <div className="Head_of_Planning_______________Class">
              <span>Head of Planning  </span><span style={{ fontStyle: 'normal', fontWeight: 'normal' }}>                                                                                                   January 2019 - January 2020<br /><br />Organise delivery of software products and end-to-end Omni-channel campaigns developed in-house to create seamless customer experience across channels for clients. Determine incoming project documentation and allocate resources needed for each particular project. Help to prioritise day-to-day tasks during daily stand-ups with designers and developers. Increase the delivery rate of projects while ensuring the tolerances in quality are maintained. <br /><br />Key Accomplishments: <br /><br />Increased workload of the studio with 30% by delivering and coordinating over 450 campaigns.<br />Managed the development of internal software tools that improve the build and delivery of end to end email digital campaigns.<br /></span><br />
            </div>
            <div className="Skills_Content_Class">
              <div className="Business_Process_Architect_____Class">
                <span>Business Process Architect	</span><span style={{ fontStyle: 'normal', fontWeight: 'normal' }}>                                                                                    March 2018 – January 2019<br /><br />Developed business process requirements by helping teams to enhance smooth progress and successful integration. Created and maintained business process documentation for training to enhance employee’s performance. Planned, implemented, and supervised business process changes for main digital outputs of the company.<br /><br />Key Accomplishments: <br /><br />Improved by 20% of work efficiency for digital designers, front, and back-end developers implementing Lean principles.<br />Implemented a new way of working using Teams, part of the Microsoft suite, by delivering transition from a decentralised approach to ‘Close to zero emails’.</span><br />
              </div>
            </div>
            <div className="Skills_Content_be_Class">
              <div className="Digital_Project_Manager________Class">
                <span>Digital Project Manager</span><span style={{ fontStyle: 'normal', fontWeight: 'normal' }}>	                                                                                     November 2016 – March 2018<br />Co-ordinated with development and product teams to ensure smooth communication and prioritization of work focused on improving email marketing campaigns. Formulated test and risk plans that kept deliverables on track and budget by engaging agile project management methodology using daily stand-ups and fortnight sprints. <br /><br />Key Accomplishments: <br /><br />Maximised revenue to Sainsbury’s online marketing division by delivering prototype Active Content Platform that incorporates business requirements.<br />Managed and enhanced clients’ campaign executive team starting from pre-knowledge transfer phase to business-as-usual.</span><br />
              </div>
            </div>
            <svg className="Path_1" viewBox="0 0 1067.663 1056.396">
              <path fill="rgba(255,255,255,1)" className="Path_1_Class" d="M -4.272559453966096e-05 0 L 1062.787353515625 0 L 1067.663330078125 1056.396362304688 L -4.272559453966096e-05 1056.396362304688 L -4.272559453966096e-05 0 Z">
              </path>
            </svg>
            <div className="Skills_Content_bh_Class">
              <div className="Head_of_Planning_______________bi_Class">
                <span>Head of Planning  </span><span style={{ fontStyle: 'normal', fontWeight: 'normal' }}>                                                                                                   January 2019 - January 2020<br /><br />Organise delivery of software products and end-to-end Omni-channel campaigns developed in-house to create seamless customer experience across channels for clients. Determine incoming project documentation and allocate resources needed for each particular project. Help to prioritise day-to-day tasks during daily stand-ups with designers and developers. Increase the delivery rate of projects while ensuring the tolerances in quality are maintained. <br /><br />Key Accomplishments: <br /><br />Increased workload of the studio with 30% by delivering and coordinating over 450 campaigns.<br />Managed the development of internal software tools that improve the build and delivery of end to end email digital campaigns.<br /></span><br />
              </div>
            </div>
            <div className="Skills_Content_bj_Class">
              <div className="Business_Process_Architect_____bk_Class">
                <span>Business Process Architect	</span><span style={{ fontStyle: 'normal', fontWeight: 'normal' }}>                                                                                     March 2018 – January 2019<br /><br />Developed business process requirements by helping teams to enhance smooth progress and successful integration. Created and maintained business process documentation for training to enhance employee’s performance. Planned, implemented, and supervised business process changes for main digital outputs of the company.<br /><br />Key Accomplishments: <br /><br />Improved by 20% of work efficiency for digital designers, front, and back-end developers implementing Lean principles.<br />Implemented a new way of working using Teams, part of the Microsoft suite, by delivering transition from a decentralised approach to ‘Close to zero emails’.</span><br />
              </div>
            </div>
            <div className="Skills_Content_bl_Class">
              <div className="Digital_Project_Manager________bm_Class">
                <span>Digital Project Manager</span><span style={{ fontStyle: 'normal', fontWeight: 'normal' }}>	                                                                                      November 2016 – March 2018<br /><br />Co-ordinated with development and product teams to ensure smooth communication and prioritization of work focused on improving email marketing campaigns. Formulated test and risk plans that kept deliverables on track and budget by engaging agile project management methodology using daily stand-ups and fortnight sprints. <br /><br />Key Accomplishments: <br /><br />Maximised revenue to Sainsbury’s online marketing division by delivering prototype Active Content Platform that incorporates business requirements.<br />Managed and enhanced clients’ campaign executive team starting from pre-knowledge transfer phase to business-as-usual.</span><br />
              </div>
            </div>
          </div>
          <div className="Component_18___1 Component_18___1_Class">
            <div className="Skills_Content_bo_Class">
              <div className="Business_Developer___Corporate_Class">
                <span>Business Developer | Corporate Office Solutions | Bucharest – Romania | May 2013 – September 2016<br />Executive Personal Assistant | King Crown Online Services Ltd | Bucharest – Romania | Nov 2012 – Feb 2013 Apprenticeship | PwC Romania &amp; VBD Alliance Consulting | Bucharest – Romania | May 2012 – October 2012<br />Work and Travel | Student Exchange Program | Cedar Point Amusement Park, USA | May 2010 – October 2010</span>
              </div>
            </div>
            <div className="About_Content_bq_Class">
              <div className="About_Title_br_Class">
                <div className="Additional_Professional_Experi_Class">
                  <span>Additional Professional Experience</span>
                </div>
                <div className="Hint_logo_bt_Class">
                  <svg className="Line_5_bu" viewBox="0 0 22 9">
                    <path fill="transparent" stroke="rgba(236,54,141,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_5_bu_Class" d="M 22 0 L 0 9">
                    </path>
                  </svg>
                  <svg className="Line_2_bv" viewBox="0 0 24 2">
                    <path fill="transparent" stroke="rgba(39,149,178,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_2_bv_Class" d="M 24 0 L 0 0">
                    </path>
                  </svg>
                  <svg className="Line_4_bw" viewBox="0 0 22 9">
                    <path fill="transparent" stroke="rgba(83,216,251,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_4_bw_Class" d="M 0 0 L 22 9">
                    </path>
                  </svg>
                </div>
              </div>
            </div>
            <svg className="Rectangle_9">
              <rect fill="rgba(255,255,255,1)" className="Rectangle_9_Class" rx={0} ry={0} x={0} y={0} width={1068} height={149}>
              </rect>
            </svg>
            <div className="Skills_Content_by_Class">
              <div className="Business_Developer___Corporate_bz_Class">
                <span>Business Developer | Corporate Office Solutions | Bucharest – Romania | May 2013 – September 2016<br />Executive Personal Assistant | King Crown Online Services Ltd | Bucharest – Romania | Nov 2012 – Feb 2013 Apprenticeship | PwC Romania &amp; VBD Alliance Consulting | Bucharest – Romania | May 2012 – October 2012<br />Work and Travel | Student Exchange Program | Cedar Point Amusement Park, USA | May 2010 – October 2010</span>
              </div>
            </div>
          </div>

          <div className="Component_17___1 Component_17___1_Class">
            <svg className="Rectangle_7">
              <rect fill="rgba(255,255,255,1)" stroke="rgba(112,112,112,1)" strokeWidth="1px" strokeLinejoin="miter" strokeLinecap="butt" strokeMiterlimit={4} shapeRendering="auto" className="Rectangle_7_Class" rx={0} ry={0} x={0} y={0} width={1063} height={287}>
              </rect>
            </svg>
            <div className="Skills_Content_cf_Class">
              <div className="Skills_Title_cg_Class">
                <a target="_blank" rel="noopener noreferrer" href="https://generalassemb.ly/">
                  <div className="General_Assembly_Class">
                    <span>General Assembly</span>
                  </div>
                </a>
                <div className="Hint_logo_ci_Class">
                  <svg className="Line_5_cj" viewBox="0 0 23 10">
                    <path fill="transparent" stroke="rgba(236,54,141,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_5_cj_Class" d="M 23 0 L 0 10">
                    </path>
                  </svg>
                  <svg className="Line_2_ck" viewBox="0 0 24 2">
                    <path fill="transparent" stroke="rgba(39,149,178,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_2_ck_Class" d="M 24 0 L 0 0">
                    </path>
                  </svg>
                  <svg className="Line_4_cl" viewBox="0 0 22 10">
                    <path fill="transparent" stroke="rgba(83,216,251,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_4_cl_Class" d="M 0 0 L 22 10">
                    </path>
                  </svg>
                </div>
              </div>
              <div className="Software_Engineering_Immersive_Class">
                <span>Software Engineering Immersive Students </span><span style={{ fontStyle: 'normal', fontWeight: 'normal' }}>                                                             February 2020 - April 2020<br /><br />Engaging daily in stand-ups, lectures and labs exposed me to more than 500 hours of professional training that had a focus on teaching the skills to build full-stack apps.<br /><br />Key Accomplishments: <br /><br />Learned core attributes in software development. <br />Built and deployed 4 projects with various requirements following the curriculum, working alone or in groups. From simple JavaScript programmes to Python backend apps with React frontend, covering all the major subjects for creating a path to further improvements in the tech industry.  <br /></span><br />
              </div>
            </div>
            <svg className="Rectangle_11">
              <rect fill="rgba(255,255,255,1)" className="Rectangle_11_Class" rx={0} ry={0} x={0} y={0} width={1068} height={292}>
              </rect>
            </svg>
            <div className="Skills_Content_co_Class">
              <div className="Software_Engineering_Immersive_cp_Class">
                <span>Software Engineering Immersive Students </span><span style={{ fontStyle: 'normal', fontWeight: 'normal' }}>                                                             February 2020 - April 2020<br /><br />Engaging daily in stand-ups, lectures and labs exposed me to more than 500 hours of professional training that had a focus on teaching the skills to build full-stack apps.<br /><br />Key Accomplishments: <br /><br />Learned core attributes in software development. <br />Built and deployed 4 projects with various requirements following the curriculum, working alone or in groups. From simple JavaScript programmes to Python backend apps with React frontend, covering all the major subjects for creating a path to further improvements in the tech industry.  <br /></span><br />
              </div>
            </div>
          </div>
          <div className="Component_14___4 Component_14___4_Class">
            <svg className="Rectangle_18">
              <rect fill="rgba(255,255,255,1)" className="Rectangle_18_Class" rx={0} ry={0} x={0} y={0} width={182} height={48}>
              </rect>
            </svg>
            <svg className="Rectangle_17">
              <rect fill="rgba(255,255,255,1)" className="Rectangle_17_Class" rx={0} ry={0} x={0} y={0} width={161} height={48}>
              </rect>
            </svg>
            <svg className="Rectangle_16">
              <rect fill="rgba(255,255,255,1)" className="Rectangle_16_Class" rx={0} ry={0} x={0} y={0} width={185} height={48}>
              </rect>
            </svg>
            <svg className="Rectangle_15">
              <rect fill="rgba(255,255,255,1)" className="Rectangle_15_Class" rx={0} ry={0} x={0} y={0} width={214} height={48}>
              </rect>
            </svg>
            <div className="Component_4___1 Component_4___1_Class">
              <div className="Skills_Content_cw_Class">
                <div className="Skills_Title_cx_Class">
                  <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/gabrielantonica/">
                    <div className="LinkedIn_c_Class">
                      <span>LinkedIn</span>
                    </div>
                  </a>
                  <div className="Hint_logo_cz_Class">
                    <svg className="Line_5_c" viewBox="0 0 2 24">
                      <path fill="transparent" stroke="rgba(236,54,141,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_5_c_Class" d="M 0 0 L 0 24">
                      </path>
                    </svg>
                    <svg className="Line_2_c" viewBox="0 0 2 24">
                      <path fill="transparent" stroke="rgba(39,149,178,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_2_c_Class" d="M 0 0 L 0 24">
                      </path>
                    </svg>
                    <svg className="Line_4_c" viewBox="0 0 2 24">
                      <path fill="transparent" stroke="rgba(83,216,251,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_4_c_Class" d="M 0 0 L 0 24">
                      </path>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="Skills_Content_c_Class">
                <div className="Skills_Title_c_Class">
                  <a target="_blank" rel="noopener noreferrer" href="https://github.com/bababumBab">
                    <div className="Github_db_Class">
                      <span>Github</span>
                    </div>
                  </a>
                  <div className="Hint_logo_dc_Class">
                    <svg className="Line_5_dd" viewBox="0 0 2 24">
                      <path fill="transparent" stroke="rgba(236,54,141,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_5_dd_Class" d="M 0 0 L 0 24">
                      </path>
                    </svg>
                    <svg className="Line_2_de" viewBox="0 0 2 24">
                      <path fill="transparent" stroke="rgba(39,149,178,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_2_de_Class" d="M 0 0 L 0 24">
                      </path>
                    </svg>
                    <svg className="Line_4_df" viewBox="0 0 2 24">
                      <path fill="transparent" stroke="rgba(83,216,251,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_4_df_Class" d="M 0 0 L 0 24">
                      </path>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="Skills_Content_dg_Class">
                <div className="Skills_Title_dh_Class">
                  <div className="Hint_logo_dn_Class">
                    <svg className="Line_5_do" viewBox="0 0 2 24">
                      <path fill="transparent" stroke="rgba(236,54,141,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_5_do_Class" d="M 0 0 L 0 24">
                      </path>
                    </svg>
                    <svg className="Line_2_dp" viewBox="0 0 2 24">
                      <path fill="transparent" stroke="rgba(39,149,178,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_2_dp_Class" d="M 0 0 L 0 24">
                      </path>
                    </svg>
                    <svg className="Line_4_dq" viewBox="0 0 2 24">
                      <path fill="transparent" stroke="rgba(83,216,251,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_4_dq_Class" d="M 0 0 L 0 24">
                      </path>
                    </svg>
                  </div>
                  <a target="_blank" rel="noopener noreferrer" href="mailto:gabriel@antonica.dev">
                    <div className="Email_Class">
                      <span>Email</span>
                    </div>
                  </a>
                  <div className="Hint_logo_dn_Class">
                    <svg className="Line_5_do" viewBox="0 0 2 24">
                      <path fill="transparent" stroke="rgba(236,54,141,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_5_do_Class" d="M 0 0 L 0 24">
                      </path>
                    </svg>
                    <svg className="Line_2_dp" viewBox="0 0 2 24">
                      <path fill="transparent" stroke="rgba(39,149,178,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_2_dp_Class" d="M 0 0 L 0 24">
                      </path>
                    </svg>
                    <svg className="Line_4_dq" viewBox="0 0 2 24">
                      <path fill="transparent" stroke="rgba(83,216,251,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_4_dq_Class" d="M 0 0 L 0 24">
                      </path>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="Skills_Content_dr_Class">
                <div className="Skills_Title_ds_Class">
                  <div className="Hint_logo_dy_Class">
                    <svg className="Line_5_dz" viewBox="0 0 2 24">
                      <path fill="transparent" stroke="rgba(236,54,141,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_5_dz_Class" d="M 0 0 L 0 24">
                      </path>
                    </svg>
                    <svg className="Line_2_d" viewBox="0 0 2 24">
                      <path fill="transparent" stroke="rgba(39,149,178,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_2_d_Class" d="M 0 0 L 0 24">
                      </path>
                    </svg>
                    <svg className="Line_4_d" viewBox="0 0 2 24">
                      <path fill="transparent" stroke="rgba(83,216,251,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_4_d_Class" d="M 0 0 L 0 24">
                      </path>
                    </svg>
                  </div>
                  <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/GabiAntonica">
                    <div className="Twitter_Class">
                      <span>Twitter</span>
                    </div>
                  </a>
                  <div className="Hint_logo_dy_Class">
                    <svg className="Line_5_dz" viewBox="0 0 2 24">
                      <path fill="transparent" stroke="rgba(236,54,141,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_5_dz_Class" d="M 0 0 L 0 24">
                      </path>
                    </svg>
                    <svg className="Line_2_d" viewBox="0 0 2 24">
                      <path fill="transparent" stroke="rgba(39,149,178,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_2_d_Class" d="M 0 0 L 0 24">
                      </path>
                    </svg>
                    <svg className="Line_4_d" viewBox="0 0 2 24">
                      <path fill="transparent" stroke="rgba(83,216,251,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_4_d_Class" d="M 0 0 L 0 24">
                      </path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </>
  )
}

export default Experience
