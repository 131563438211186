import React from 'react'
import { Link } from 'react-router-dom'


import Headshot_c_pattern from '../styles/assets/Headshot_c_pattern.png'
import heroImage2x from '../styles/assets/heroImage2x.png'



const HomePage = () => {

  return (
    <>
      <div className="homepage">

        <div className="GA_Home_Full_Hero_Class">
          <div className="Component_15___1 Component_15___1_Class">
            <div className="Hero_Image Hero_Image_Class">
              <img src={heroImage2x} className="Rectangle_1" />

              {/* <img className="sannah_vinding_VZxmuCgRH3c_uns_Class" src="sannah_vinding_VZxmuCgRH3c_uns.png" srcSet="sannah_vinding_VZxmuCgRH3c_uns.png 1x, sannah_vinding_VZxmuCgRH3c_uns@2x.png 2x" />
              <img className="sannah_vinding_VZxmuCgRH3c_uns_n_Class" src="sannah_vinding_VZxmuCgRH3c_uns_n.png" srcSet="sannah_vinding_VZxmuCgRH3c_uns_n.png 1x, sannah_vinding_VZxmuCgRH3c_uns_n@2x.png 2x" /> */}
              <svg className="Rectangle_2_p">

                <radialGradient id="Rectangle_2_p" spreadMethod="pad" x1={0} x2={0} y1={0} y2={0}>
                  <stop offset={0} stopColor="#000" stopOpacity={1} />
                  <stop offset={1} stopColor="#fff" stopOpacity={0} />
                </radialGradient>
               
              </svg>
            </div>
            <div className="Hero_Text_Class">
              <div className="Logo_Class">
                <div className="Antonica_Class">
                  <div className="A_Class">
                    <span>A</span>
                  </div>
                  <div className="N_Class">
                    <span>N</span>
                  </div>
                  <div className="T_Class">
                    <span>T</span>
                  </div>
                  <div className="O_Class">
                    <span>O</span>
                  </div>
                  <div className="N_x_Class">
                    <span>N</span>
                  </div>
                  <div className="I_Class">
                    <span>I</span>
                  </div>
                  <div className="C_Class">
                    <span>C</span>
                  </div>
                  <div className="A__Class">
                    <span>A</span>
                  </div>
                </div>
                <div className="Gabriel_Class">
                  <div className="G_Class">
                    <span>G</span>
                  </div>
                  <div className="A_ba_Class">
                    <span>A</span>
                  </div>
                  <div className="B_Class">
                    <span>B</span>
                  </div>
                  <div className="R_Class">
                    <span>R</span>
                  </div>
                  <div className="I__Class">
                    <span>I</span>
                  </div>
                  <div className="E_Class">
                    <span>E</span>
                  </div>
                  <div className="L_Class">
                    <span>L</span>
                  </div>
                </div>
              </div>
              <div className="Icon_5_way_Class">
                <div className="G_ba_Class">
                  <span>G</span>
                </div>
                <div className="G_bb_Class">
                  <span>G</span>
                </div>
                <div className="G_bc_Class">
                  <span>G</span>
                </div>
                <div className="G_bd_Class">
                  <span>G</span>
                </div>
                <div className="G_be_Class">
                  <span>G</span>
                </div>
              </div>
              <div className="Technical_Project_Manager___Ju_Class">
                <span>Technical Project Manager | Junior Software Developer</span>
              </div>
            </div>
          </div>

          <div className="Nav_bar Nav_bar_Class">
            <Link to='/about'>
              <div className="ABOUT_Class">
                <span>ABOUT</span>
              </div>
            </Link>
            <Link to='/projects'>
              <div className="PROJECTS_Class">
                <span>PROJECTS</span>
              </div>
            </Link>
            <Link to='/experience'>
              <div className="PROFFESIONAL_EXPERIENCE_Class">
                <span>PROFFESSIONAL EXPERIENCE</span>
              </div>
            </Link>
          </div>
          <div className="About_Content About_Content_Class">
            <div className="Dedicated_professional_with_ex_Class">
              <span>Dedicated professional with experience in planning, delivering software projects, and digital marketing campaigns; I am constantly driven by an always-on desire to support, learn and develop.<br /><br />Assisting the team that I am part of is my foremost drive. Always seeking to achieve more, in an efficient way, completes my desire to acquire the necessary skills. <br /><br />Joining General Assembly on the Software Development Immersive course meant that my passion for web development was fulfilled. Nurturing the understanding of different programming languages, methodologies and processes, prepared me for the role of a Junior Software Developer with a project management twist embedded.</span><br />
            </div>
            <div className="About_Title_Class">
              <div className="About_Class">
                <span>About</span>
              </div>
              <div className="Hint_logo_Class">
                <svg className="Line_5" viewBox="0 0 2 24">
                  <path fill="transparent" stroke="rgba(236,54,141,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_5_Class" d="M 0 0 L 0 24">
                  </path>
                </svg>
                <svg className="Line_2" viewBox="0 0 2 24">
                  <path fill="transparent" stroke="rgba(39,149,178,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_2_Class" d="M 0 0 L 0 24">
                  </path>
                </svg>
                <svg className="Line_4" viewBox="0 0 2 24">
                  <path fill="transparent" stroke="rgba(83,216,251,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_4_Class" d="M 0 0 L 0 24">
                  </path>
                </svg>
              </div>
            </div>
            <div className="Headshot_Class">
              <img src={Headshot_c_pattern} className="Headshot_Class" />
            </div>
          </div>
          <div className="Component_14___1 Component_14___1_Class">
            <svg className="Rectangle_18" >
              <rect fill="rgba(255,255,255,1)" className="Rectangle_18_Class" rx={0} ry={0} x={0} y={0} width={182} height={48}>
              </rect>
            </svg>

            <svg className="Rectangle_17">
              <rect fill="rgba(255,255,255,1)" className="Rectangle_17_Class" rx={0} ry={0} x={0} y={0} width={161} height={48}>
              </rect>
            </svg>
            <svg className="Rectangle_16">
              <rect fill="rgba(255,255,255,1)" className="Rectangle_16_Class" rx={0} ry={0} x={0} y={0} width={185} height={48}>
              </rect>
            </svg>
            <svg className="Rectangle_15">
              <rect fill="rgba(255,255,255,1)" className="Rectangle_15_Class" rx={0} ry={0} x={0} y={0} width={214} height={48}>
              </rect>
            </svg>

            <div className="Component_4___1 Component_4___1_Class">
              <div className="Skills_Content_Class">
                <div className="Skills_Title_Class">
                  <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/gabrielantonica/">
                    <div className="LinkedIn_b_Class">
                      <span>LinkedIn</span>
                    </div>
                  </a>
                  <div className="Hint_logo_b_Class">
                    <svg className="Line_5_b" viewBox="0 0 2 24">
                      <path fill="transparent" stroke="rgba(236,54,141,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_5_b_Class" d="M 0 0 L 0 24">
                      </path>
                    </svg>
                    <svg className="Line_2_b" viewBox="0 0 2 24">
                      <path fill="transparent" stroke="rgba(39,149,178,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_2_b_Class" d="M 0 0 L 0 24">
                      </path>
                    </svg>
                    <svg className="Line_4_b" viewBox="0 0 2 24">
                      <path fill="transparent" stroke="rgba(83,216,251,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_4_b_Class" d="M 0 0 L 0 24">
                      </path>
                    </svg>
                  </div>

                </div>
              </div>
              <div className="Skills_Content_b_Class">
                <div className="Skills_Title_ca_Class">
                  <a target="_blank" rel="noopener noreferrer" href="https://github.com/bababumBab">
                    <div className="Github_cg_Class">
                      <span>Github</span>
                    </div>
                  </a>
                  <div className="Hint_logo_ch_Class">
                    <svg className="Line_5_ci" viewBox="0 0 2 24">
                      <path fill="transparent" stroke="rgba(236,54,141,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_5_ci_Class" d="M 0 0 L 0 24">
                      </path>
                    </svg>
                    <svg className="Line_2_cj" viewBox="0 0 2 24">
                      <path fill="transparent" stroke="rgba(39,149,178,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_2_cj_Class" d="M 0 0 L 0 24">
                      </path>
                    </svg>
                    <svg className="Line_4_ck" viewBox="0 0 2 24">
                      <path fill="transparent" stroke="rgba(83,216,251,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_4_ck_Class" d="M 0 0 L 0 24">
                      </path>
                    </svg>
                  </div>

                  <div className="Hint_logo_ch_Class">
                    <svg className="Line_5_ci" viewBox="0 0 2 24">
                      <path fill="transparent" stroke="rgba(236,54,141,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_5_ci_Class" d="M 0 0 L 0 24">
                      </path>
                    </svg>
                    <svg className="Line_2_cj" viewBox="0 0 2 24">
                      <path fill="transparent" stroke="rgba(39,149,178,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_2_cj_Class" d="M 0 0 L 0 24">
                      </path>
                    </svg>
                    <svg className="Line_4_ck" viewBox="0 0 2 24">
                      <path fill="transparent" stroke="rgba(83,216,251,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_4_ck_Class" d="M 0 0 L 0 24">
                      </path>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="Skills_Content_cl_Class">
                <div className="Skills_Title_cm_Class">
                  <div className="Hint_logo_cs_Class">
                    <svg className="Line_5_ct" viewBox="0 0 2 24">
                      <path fill="transparent" stroke="rgba(236,54,141,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_5_ct_Class" d="M 0 0 L 0 24">
                      </path>
                    </svg>
                    <svg className="Line_2_cu" viewBox="0 0 2 24">
                      <path fill="transparent" stroke="rgba(39,149,178,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_2_cu_Class" d="M 0 0 L 0 24">
                      </path>
                    </svg>
                    <svg className="Line_4_cv" viewBox="0 0 2 24">
                      <path fill="transparent" stroke="rgba(83,216,251,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_4_cv_Class" d="M 0 0 L 0 24">
                      </path>
                    </svg>
                  </div>
                  <a target="_blank" rel="noopener noreferrer" href="mailto:gabriel@antonica.dev">
                    <div className="Email_Class">
                      <span>Email</span>
                    </div>
                  </a>
                  <div className="Hint_logo_cs_Class">
                    <svg className="Line_5_ct" viewBox="0 0 2 24">
                      <path fill="transparent" stroke="rgba(236,54,141,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_5_ct_Class" d="M 0 0 L 0 24">
                      </path>
                    </svg>
                    <svg className="Line_2_cu" viewBox="0 0 2 24">
                      <path fill="transparent" stroke="rgba(39,149,178,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_2_cu_Class" d="M 0 0 L 0 24">
                      </path>
                    </svg>
                    <svg className="Line_4_cv" viewBox="0 0 2 24">
                      <path fill="transparent" stroke="rgba(83,216,251,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_4_cv_Class" d="M 0 0 L 0 24">
                      </path>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="Skills_Content_cw_Class" >
                <div className="Skills_Title_cx_Class" >
                  <div className="Hint_logo_c_Class">
                    <svg className="Line_5_c" viewBox="0 0 2 24">
                      <path fill="transparent" stroke="rgba(236,54,141,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_5_c_Class" d="M 0 0 L 0 24">
                      </path>
                    </svg>
                    <svg className="Line_2_da" viewBox="0 0 2 24">
                      <path fill="transparent" stroke="rgba(39,149,178,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_2_da_Class" d="M 0 0 L 0 24">
                      </path>
                    </svg>
                    <svg className="Line_4_da" viewBox="0 0 2 24">
                      <path fill="transparent" stroke="rgba(83,216,251,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_4_da_Class" d="M 0 0 L 0 24">
                      </path>
                    </svg>
                  </div>
                  <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/GabiAntonica">
                    <div className="Twitter_Class">
                      <span>Twitter</span>
                    </div>
                  </a>
                  <div className="Hint_logo_c_Class">
                    <svg className="Line_5_c" viewBox="0 0 2 24">
                      <path fill="transparent" stroke="rgba(236,54,141,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_5_c_Class" d="M 0 0 L 0 24">
                      </path>
                    </svg>
                    <svg className="Line_2_da" viewBox="0 0 2 24">
                      <path fill="transparent" stroke="rgba(39,149,178,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_2_da_Class" d="M 0 0 L 0 24">
                      </path>
                    </svg>
                    <svg className="Line_4_da" viewBox="0 0 2 24">
                      <path fill="transparent" stroke="rgba(83,216,251,1)" strokeWidth="2px" strokeLinejoin="miter" strokeLinecap="round" strokeMiterlimit={4} shapeRendering="auto" className="Line_4_da_Class" d="M 0 0 L 0 24">
                      </path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>



      </div>
    </>
  )
}

export default HomePage
